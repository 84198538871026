

@use '../../../../style/import' as *;




////////////
// Events //
////////////


// Titan colors
// None yet

// Theme colors
$eventsBackground: $THEME_BACKGROUND;

// General variables
$eventsMargin: 10px;


.Events {

	
	.bg0.calendarWrapper {
		background-image: url('../img/1tdm.png');
	}	

	.bg1.calendarWrapper {
		background-image: url('../img/2tdm.png');
	}	

	.bg2.calendarWrapper {
		background-image: url('../img/3tdm.png');
	}	

	.calendarWrapper {
		position: relative;
		padding: 2*$eventsMargin 2*$eventsMargin 2*$eventsMargin 2*$eventsMargin;
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		// opacity: 0.4;
		

		.calendarScreen {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			// background: rgba($eventsBackground, $eventsBackgroundOpacity);
		}
	}
}


